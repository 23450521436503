import * as THREE from "../node_modules/potree/libs/three.js/build/three.module.js";
import { ApiURL } from "./constants.js";

window.originalImageRotatation = null;

export function createImageRotationEditor(sphere, image) {
    window.originalImageRotatation = {
        course: image.course,
        pitch: image.pitch,
        roll: image.roll
    }

    const paramEditor = (label, value, callback) => {
        const el = document.createElement('div');
        el.className = 'param-editor';

        const labelEl = document.createElement('div');
        labelEl.textContent = label + ':';
        el.appendChild(labelEl);
        const valEl = document.createElement('div');
        valEl.textContent = value.toFixed(2);
        el.appendChild(valEl);

        const cb_creator = (step) => {
            return () => {
                value += step;
                valEl.textContent = value.toFixed(2);
                callback(value);
            }
        }

        const buttons = [
            ['-', -0.1, ''],
            ['-', -1, 'lg'],
            ['+', 1, 'lg'],
            ['+', 0.1, '']
        ];

        buttons.forEach((btn) => {
            let [ label, step, extraClass ] = btn;
            const btnEl = document.createElement('div');
            btnEl.className = 'button ' + extraClass;
            btnEl.textContent = label;
            btnEl.onclick = cb_creator(step);

            el.appendChild(btnEl);
        });

        return el;
    }

    const container = document.createElement('div');
    container.id = 'image-rotation-edit';

    const title = document.createElement('h3');
    title.textContent = 'Foto rotatie';
    container.appendChild(title);

    container.appendChild(paramEditor('Course', image.course, (newCourse) => {
        image.course = newCourse;
        updateImageRotationInView(sphere, image);
    }));
    container.appendChild(paramEditor('Pitch', image.pitch, (newPitch) => {
        image.pitch = newPitch;
        updateImageRotationInView(sphere, image);
    }));
    container.appendChild(paramEditor('Roll', image.roll, (newRoll) => {
        image.roll = newRoll;
        updateImageRotationInView(sphere, image);
    }));

    const resetBtn = document.createElement('div');
    resetBtn.textContent = 'Reset';
    resetBtn.className = 'reset-btn';
    resetBtn.onclick = () => {
        let { course, pitch, roll } = window.originalImageRotatation;
        image.course = course;
        image.pitch = pitch;
        image.roll = roll;
        updateImageRotationInView(sphere, window.originalImageRotatation);
        createImageRotationEditor(sphere, image);
    }
    container.appendChild(resetBtn);

    const saveBtn = document.createElement('div');
    saveBtn.textContent = 'Opslaan';
    saveBtn.className = 'save-btn';
    saveBtn.onclick = () => {
        save(image);
    }
    container.appendChild(saveBtn);

    const previousEditor = window.imagesRotationEditor;
    const renderArea = document.getElementById('potree_render_area');
    if (previousEditor) {
        renderArea.removeChild(previousEditor);
    }
    renderArea.appendChild(container);
    window.imagesRotationEditor = container;
}

export function removeImageRotationEditor() {
    if (window.imagesRotationEditor) {
        const renderArea = document.getElementById('potree_render_area');
        renderArea.removeChild(window.imagesRotationEditor);
    }

    window.imagesRotationEditor = null;
}

function save(image) {
    const data = {
        scan_id: window.loadedScanID,
        image: image.file.split('/').pop(),
        course: image.course,
        pitch: image.pitch,
        roll: image.roll
    };

    $.ajax({
        type: 'POST',
        url: `${ApiURL}/scans/update-image-rotation/`,
        data: data,
        dataType: 'json',
        success: (response) => {
            console.log(response);
        },
        error: console.error
    });
}

function updateImageRotationInView(sphere, rotation) {
    let {course, pitch, roll} = rotation;
    sphere.rotation.set(
        THREE.Math.degToRad(+roll + 90),
        THREE.Math.degToRad(-pitch),
        THREE.Math.degToRad(-course + 90),
        "ZYX"
    );
}