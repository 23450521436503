export const ApiURL = env.APIURL;
export const BaseURL = env.BASEURL || "";

export function createUrl(path) {
    if (path.includes('http')) {
        return path.replace(new RegExp('([^:])(//+)', 'g'), (m) => `${m[0]}${m[1].replace(new RegExp("//+"), "/")}`);
    } else {
        const host = window.location.protocol + "//" + window.location.host;

        let base = BaseURL;
        if (path.startsWith("/")) {
            // Absolute path
    
            // BaseUrl already added
            if (path.startsWith(BaseURL)) {
                base = "";
            }
        } else {
            // Relative path
            if (path.startsWith('./')) {
                base = window.location.pathname;
                path = path.replace("./", "");
            } 
        }

        return (host + '/' + base + '/' + path).replace(new RegExp('([^:])(//+)', 'g'), (m) => `${m[0]}${m[1].replace(new RegExp("//+"), "/")}`);
    }
}