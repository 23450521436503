export { handleLogin } from './auth';

import "../node_modules/potree/build/potree/potree";
import { checkAuth } from './auth';
import { Sidebar } from './sidebar';
import { loadPointCloud } from './loader';
import { BaseURL, createUrl, ApiURL } from './constants';

window.onload = () => {
    // Tell Potree it's location on our server
    
    Potree.scriptPath = createUrl('potree');
    Potree.resourcePath = createUrl('potree/resources');

    window.sidebar = new Sidebar();

    const handleData = (userdata, scans) => {
        window.viewer = new Potree.Viewer(document.getElementById("potree_render_area"));
        window.isAdmin = userdata.user_type.name === "admin";

        viewer.setEDLEnabled(true);
        viewer.setFOV(60);
        viewer.setPointBudget(2 * 1000 * 1000);
        viewer.loadSettingsFromURL();
        
        viewer.loadGUI(() => {
            viewer.setLanguage('en');
            $("#menu_tools").next().show();

            sidebar.init();
            sidebar.removeAbout();
            sidebar.removeFilter();
            sidebar.removeTools()
            sidebar.removeAppearance();
            sidebar.removeSceneExport();
            sidebar.clearHeader();
            sidebar.addPoweredByPotree();
            sidebar.addLogo(createUrl('assets/logo.png'));
            //sidebar.addImportButton(importMeasurements);
            sidebar.addSignoutButton();
            sidebar.addUserData(userdata.username, userdata.user_type.label);
            // sidebar.addProjectSection();

            viewer.toggleSidebar();

            if (scans.length === 0) {

            } else {
                if (scans.length > 1) {
                    sidebar.addFileBrowser(scans, loadPointCloud, scans[0].name);
                }

                // Load first
                loadPointCloud(scans[0].name, scans[0]);
            }
        });
    }

    checkAuth()
        .then(
            (userdata) => {
                $.ajax({
                    dataType: "json",
                    url: `${ApiURL}/scans/`,
                    headers: {
                        'Authorization': `Token ${window.authToken}`
                    },
                    type: 'GET',
                    success: (data, status, xhr) => {
                        handleData(userdata, data)
                    },
                    error: console.error
                });
            }, () => {
                if (!window.location.href.includes('auth')) {
                    window.location.href = createUrl('auth'); 
                }}
        )
}