import { Cookie, AUTH_COOKIE_NAME } from "./cookie";
import { ApiURL, createUrl } from "./constants";

function showAuthError(t) {
    $('.error').text(t);
    $('.error').show();
}

function hideAuthError() {
    $('.error').hide();
}

export function checkAuth() {
    return new Promise((resolve, reject) => {
        const token = Cookie.get(AUTH_COOKIE_NAME);
		if (token.length > 0) {
            $.ajax({
				type: 'GET',
				url: `${ApiURL}/user/`,
				headers: {
					'Authorization': `Token ${token}`
				},
				success: (userdata, status, xhr) => {
                    window.authToken = token;
                    resolve(userdata);
				},
                error: reject
			});
        } else {
            reject();
        }
    });
}
    
export function handleLogin() {
    const username = $('#username').val();
    const password = $('#password').val();

    if (!username || !password || username.length === 0 || password.length === 0) {
        showAuthError("Vul a.u.b. een gebruikersnaam en een wachtwoord in.");
    } else {
        hideAuthError();

        let data = new FormData();
        data.append('username', username);
        data.append('password', password);

        $.ajax({
            type: 'POST',
            url: `${ApiURL}/auth/get-token/`,
            processData: false,
            contentType: false,
            data: data,
            success: (data, status, xhr) => {
                if ('token' in data) {
                    Cookie.set(AUTH_COOKIE_NAME, data.token, 1);
                    window.location.href = createUrl("");
                }
            },
            error: (e) => {
                showAuthError('Er is iets fout gegaan met inloggen. Controleer de combinatie van gebruikersnaam en wachtwoord en probeer het opnieuw.');
            }
        })
    }
}