import JSON5 from "../node_modules/json5/dist/index.mjs";
import { Cookie, AUTH_COOKIE_NAME } from './cookie';
import "../node_modules/potree/build/potree/potree";

export class Sidebar {

    constructor() {}

    init() {
        this._sidebar = $('#sidebar_root');

        this._sidebar.find("#potree_menu").next().remove();
    }

    removeAbout() {
        const about = this._sidebar.find('#menu_about');
        about.next().remove();
        about.remove();
    }

    removeTools(){
        const childrenExceptFirstTwo = this._sidebar.find('#menu_tools').nextAll('.pv-menu-list').first().children().slice(2);
        childrenExceptFirstTwo.next().remove();
        childrenExceptFirstTwo.remove();
    }

    removeSceneExport() {
        const exportvar = this._sidebar.find('#scene_export');
        exportvar.next().remove();
        exportvar.remove();
    }

    removeFilter() {
        const filtersmenu = this._sidebar.find('#menu_filters').next();
        filtersmenu.prev().css('visibility', 'hidden'); // something interacts with this element, so removing it casuses bugs
    }

    removeAppearance() {
        const classificationList = this._sidebar.find('#menu_appearance').next();
        classificationList.prev().remove();
        classificationList.remove();
    }

    clearHeader() {
        this._sidebar.find('#sidebar_header').empty();
    }

    addLogo(src) {
        let section = $(`<div style="display: flex; justify-content: center; padding: 25px; background-color:whitesmoke"><div><img src="${src}" style="max-width: 100%;" /></div></div>`);
        this._sidebar.find('#sidebar_header').prepend(section);
    }

    addPoweredByPotree() {
        let section = $(`<div style="display: flex; justify-content: center; padding: 20px 0px;"><div>Powered by <b>Potree</b></div></div>`);
        this._sidebar.append(section);
    }

    clearScenePanel() {
        const menu = this._sidebar.find('#menu_scene').next();
        menu.find('#scene_export').empty();
        menu.find('#scene_objects').empty();
        menu.find('#scene_object_properties').empty();
    }

    addFileBrowser(data, onclick, selected) {
        const browser = $('#file_browser');
        if (browser) {
            browser.next().remove();
            $('#file_browser').remove();
        }

        let that = this;

        let section = $(`
            <h3 id="file_browser" class="accordion ui-widget"><span>Scans</span></h3>
            <div class="" style="display: flex; flex-direction: column; padding: 25px;"></div>
        `);
        let content = section.last();
        
        data.forEach((entry) => {
            let element;
            if (entry.name === selected) {
                element = $(`<div style="padding: 5px 0px; cursor: pointer;"><b>${entry.name}</b></div>`);
            } else {
                element = $(`<div style="padding: 5px 0px; cursor: pointer;">${entry.name}</div>`);
                element.click((e) => { onclick(entry.name, entry); that.addFileBrowser(data, onclick, entry.name) });
            }

            content.append(element);
        });
        section.first().click(() => content.slideToggle());
        section.insertBefore($('#menu_tools'));
    }

    addImportButton(onclick) {
        // Filters field
        const el = $('#menu_filters').next()

        let section = $(`<button style="width: 100%; background-color: white; height: 30px; margin-top: 5px;">Import</button>`);
        section.click((e) => onclick());

        el.after(section);
    }

    loadCustomDefaultAppearance(viewer){
        viewer.setEDLEnabled(true);
        viewer.setFOV(60);
        viewer.setPointBudget(2000000);
        viewer.loadSettingsFromURL();
    }

    addProjectSection(){
        //      insert before #menu_tools section. Make sure to update the code in case you remove the tools section from the sidebar
        

        // HEADER
        let section = $(`
        <h3 id="menu_project" class="accordion-header ui-widget"><span>Project</span></h3>
        <div class="accordion-content ui-widget pv-menu-list"></div>
        `);

        // CONTENT
        let content = section.last();
        content.html(`
        <div class="pv-menu-list">

        <button class="import_btn" style="width: 100%; background-color: white; margin-top: 5px; overflow-wrap: break-word">Import</button>
            
        </div>
        `);
        content.hide();

        // WRAP UP
        section.first().click(() => content.slideToggle());
        section.insertBefore($('#menu_tools'));


        // FUNCTIONALITY
        $('.import_btn').on('click', this.importMeasurementsFromPotreeFile)

    }

    importMeasurements() {
        const scene = viewer.scene;

        $.getJSON(`./measurements/${currentProjectName}/metingen.json`, (data) => {
            if (data && 'features' in data) {
                data.features.forEach((f) => {
                    try {
                        if (f['geometry']['type'] === 'LineString') {
                            let measure = new Potree.Measure();
                            measure.name = f['properties']['name'];
                            if (measure.name === "Height") {
                                measure.showHeight = true;
                            }
                            measure.closed = false;

                            f['geometry']['coordinates'].forEach((c) => {
                                measure.addMarker(new THREE.Vector3( ... c));
                            });
                            
                            scene.addMeasurement(measure);
                        }

                        if (f['geometry']['type'] === 'Polygon') {
                            let measure = new Potree.Measure();
                            measure.name = f['properties']['name'];
                            if (measure.name === "Height") {
                                measure.showHeight = true;
                            }
                            measure.closed = false;
                            measure.showAngles = true;

                            f['geometry']['coordinates'].forEach((c) => {
                                measure.addMarker(new THREE.Vector3( ... c));
                            });
                            
                            scene.addMeasurement(measure);
                        }
                    } catch (e) { console.error(e)}
                    
                });
            }
        });
    }

    addUserData(name, type) {
        let section = $(`<div style="text-align: center; color: white; padding: 10px 0px;">${name} (${type})</div>`);
        $('#potree_menu').prepend(section);
    }

    addSignoutButton() {
        // Filters field
        const el = $('#potree_menu')

        let section = $(`<button style="width: 100%; background-color: white; height: 30px; margin-top: 5px;">Uitloggen</button>`);
        section.click((e) => {
            Cookie.delete(AUTH_COOKIE_NAME);
            window.location.reload()
        });

        el.append(section);
    }

    async importMeasurementsFromPotreeFile(){
        console.log('Starting import from Potree JSON5 file...')

        const response = await fetch('./data/projects/metingen.json5');
		
        const text = await response.text();
        const json = JSON5.parse(text);

        if(json.type === "Potree"){
            if ('measurements' in json) {
                json.measurements.forEach((m) => {
                    Potree.loadMeasurement(viewer, m);
                });
            }
        }
    }   
}