import { createUrl } from "./constants";
import { MapView } from "../node_modules/potree/src/viewer/map";
import { createImageRotationEditor, removeImageRotationEditor } from "./image_rotation";

/**
 * Reads a pointclouds config and transforms it to a pointcloud location, an images location and an initial camera setting
 * 
 * @param {*} config 
 * @returns 
 */
function readConfig(config) {
    let imagesLocation = null;
    if (config['imagesFolder']) {
        imagesLocation = config['imagesSrc'] == 'weburl' || config['imagesFolder'].startsWith('https://') ? config['imagesFolder'] : createUrl('./data/images/' + config['imagesFolder']);
    }

    // distinction between web source or local file source
    let ptcldBase = config['pointcloudSrc'] == 'weburl' || config['folder'].startsWith('https://') ? '' : './data/pointclouds/';
    let pointcloudLocation = createUrl(`${ptcldBase}${config['folder']}/${config['sourceFile']}`);
    let trajectoryLocation = null;
    if ("trajectoryFolder" in config && config['trajectoryFolder']) {
        trajectoryLocation = createUrl(`${ptcldBase}${config['trajectoryFolder']}${config['trajectoryFile']}`);
    }

    return [ pointcloudLocation, imagesLocation, config['initialCamera'], trajectoryLocation ];
}

/**
 * Removes the current scne from the viewer and initializes an empty one
 */
function clearViewer() {
    viewer.scene.scenePointCloud.remove(viewer.scene.pointclouds[0]);
    viewer.scene.pointclouds.pop();
    viewer.scene.removeAllMeasurements();

    viewer.setScene(new Potree.Scene());
    sidebar.clearScenePanel();
    viewer.sidebar.initScene();

    // Remove current projection from the map so we can display the new pointcloud correctly
    viewer.mapView.sceneProjection = null;

    // Remove image unfocus button from the dom. It will be added again when loading new images.
    $('input[type="button"][value="unfocus"]').remove();
    // Remove photo locations from mapview
    $('div.ol-viewport').remove();
    viewer.mapView = new MapView(viewer);
    viewer.mapView.init();
}

export function loadPointCloud(name, config) {
    window.currentProjectName = name;
    window.loadedScanID = config['id'];

    const assertBlobPrefix = (path) => {
        if (path.startsWith('https://')) {
            return path;
        } else {
            return `https://holodeckstorage.blob.core.windows.net/pointclouddata${path.startsWith('/') ? '' : '/'}${path}`;
        }
    }

    clearViewer();

    config['directory'] = assertBlobPrefix(config['directory']);
    const pointcloud_path = `/${config['pointcloud_directory']}/metadata.json`.replace("//", "/");
    const images_path = `/${config['images_directory']}`.replace('//', '/');

    let [ pointcloudLocation, imagesLocation, initialCamera, trajectoryLocation ] = [
        `${config['directory']}${pointcloud_path}`,
        `${config['directory']}${images_path}`
    ];

    // let [ pointcloudLocation, imagesLocation, initialCamera, trajectoryLocation ] = readConfig(config);

    Potree.loadPointCloud(pointcloudLocation, name, e => {
        let scene = viewer.scene;
        let pointcloud = e.pointcloud;
        
        let material = pointcloud.material;
        material.size = 1;
        material.pointSizeType = Potree.PointSizeType.FIXED;
        material.shape = Potree.PointShape.SQUARE;
        material.activeAttributeName = "elevation";
        
        scene.addPointCloud(pointcloud);
        
        if (initialCamera) {
            viewer.scene.view.setView(... initialCamera);
        } else {
            viewer.fitToScreen();
        }
        
        if (imagesLocation) {
            loadImages(imagesLocation);
        }

        viewer.setDescription(name);
        viewer.setControls(viewer.earthControls);

        // if (trajectoryLocation) {
        //     Potree.loadPointCloud(trajectoryLocation, `${name}_trajectory`, e => {
        //         let scene = viewer.scene;
        //         let pointcloud = e.pointcloud;
                
        //         let material = pointcloud.material;
        //         material.size = 1;
        //         material.pointSizeType = Potree.PointSizeType.FIXED;
        //         material.shape = Potree.PointShape.SQUARE;
        //         material.activeAttributeName = "elevation";
                
        //         scene.addPointCloud(pointcloud);
        //     });
        // }
    });
}

async function loadImages(loc){
    proj4.defs("WGS84", "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs");
    proj4.defs("pointcloud", viewer.getProjection());
    proj4.defs("images", "+proj=sterea +lat_0=52.1561605555556 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +units=m +no_defs +type=crs")

    let params = {
        transform: proj4("WGS84", "pointcloud")
        // transform: proj4("images", "pointcloud")
    };

    // Temporary disable transform for local images until we fixed the projection issue
    // if (!loc.includes('http')) {
    //     params = {};
    // }

    Potree.Images360Loader.load(loc, viewer, params).then((images) => {
        viewer.scene.add360Images(images);

        // Event when images are toggled in the sidebar
        images.addEventListener("visibility_changed", (event) => {
            // Empty intentionally
        });

        trackImageSelection(images);
    });

    viewer.mapView.showSources(false);
}

function trackImageSelection(images) {
    if (window.trackImageSelectionInterval) {
        clearInterval(window.trackImageSelectionInterval);
    }

    window.selectedImage = images.focusedImage;
    window.trackImageSelectionInterval = setInterval(() => {
                if (images.focusedImage !== window.selectedImage) {
            window.selectedImage = images.focusedImage;

            if (window.selectedImage !== null) {
                if (window.isAdmin) {
                    createImageRotationEditor(images.sphere, window.selectedImage);
                }

                // TEMPORARILY FIX: uncheck pointclouds, annotation and measurement when openening (focus) an image
                $('#pointclouds .jstree-checked .jstree-checkbox, #measurements .jstree-checked .jstree-checkbox, #annotations .jstree-checked .jstree-checkbox').click()
            } else {
                removeImageRotationEditor();

                // TEMPORARILY FIX: check pointclouds, annotation and measurement when closing (uncfocus) an image
                $('#pointclouds .jstree-checkbox, #measurements .jstree-checkbox, #annotations .jstree-checkbox').click()
            }
        }
    }, 1000);
}